import React from "react"
import Title from "../Global/Title"

export default function Objectives() {
  return (
    <section id="about-gh">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <Title title="OBJECTIVES" />
            <div className="separator" />
            <p className="mb-5">
              Remote working or service has become a new trend against the backdrop of the epidemic. Under the
              Anti-Epidemic Fund, the Innovation and Technology Commission (ITC) of Hong Kong has launched the Distance
              Business (D-Biz) Programme to support enterprises to continue their business and services during the
              epidemic, which provides funding support through fast-track processing for enterprises to adopt IT
              solutions for developing distance business.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
