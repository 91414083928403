import React from "react"
import Title from "../Global/Title"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function FundingAmount({ img }) {
  return (
    <section>
      <div>
        <div className="row d-flex align-items-center" style={{ margin: 0 }}>
          <div className="col-10 col-sm-8 col-lg-4 text-left mx-auto my-5">
            <Title title="FUNDING AMOUNT" />
            <div className="separator" />
            <div className="mb-5">
              <ul>
                <li>Funding ceiling of each IT project is <strong>HK$100,000</strong> </li>
                <li>Total funding of up to <strong>HK$300,000</strong> for each company</li>
                <li>An enterprise will be disbursed an upfront payment of <strong>30%</strong></li>
              </ul>
            </div>
            <Title title="APPLICATION PERIOD" />
            <div className="separator" />
            <div className="text-center">
            <p className="mb-5">Starting from 18 May 2020 (Details to be announced)</p>
            <Link to="/contact">
              <button className="btn default-btn text-uppercase btn-round">
                Find out more
              </button>
            </Link>
            </div>
          </div>

          <div className="side-img">
            <Img fluid={img} className="phone" />
          </div>
        </div>
      </div>
    </section>
  )
}
