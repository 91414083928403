import React from "react"
import Title from "../Global/Title"
import SVGIcon from "../D-Biz/SVGIcon"

export default function FundingScope() {
  return (
    <section className="white-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto text-center">
            <Title title="FUNDING SCOPE" />
            <div className="separator" />

            <div className="row justify-content-center icon-grid">
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="eshop" width={60} className="info-icon" />
                <h6>Eshop</h6>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="ar" width={60} className="info-icon" />
                <h6>Digital customer experience enhancement</h6>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="marketing" width={60} className="info-icon" />
                <h6>Digital marketing</h6>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="booking" width={60} className="info-icon" />
                <h6>Online booking system</h6>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="epayment" width={60} className="info-icon" />
                <h6>Digital payment</h6>
              </div>

              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="erp" width={60} className="info-icon" />
                <h6>ERP</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
