import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSection from "../components/Global/TopBanner"
import Objectives from "../components/D-Biz/Objectives"
import FundingScope from "../components/D-Biz/FundingScope"
import FundingAmount from "../components/D-Biz/FundingAmount"

const DBizPage = ({ data }) => (
  <Layout>
    <SEO title="D-Biz" keywords={[`D-Biz`, `ITC`, `funding`]} />
    <BannerSection
      img={data.img.childImageSharp.fluid}
      title="D-Biz"
      children="Distance Business Programme"
    />
    <Objectives />
    <FundingScope />
    <FundingAmount img={data.fundingImg.childImageSharp.fluid} />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "dbiz-banner-d.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    fundingImg: file(relativePath: { eq: "funding-amount-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default DBizPage
